import React from 'react';

import Layout from '../../components/MenuLayout';
import { AdvancedToolEditView } from '../../components/AdvancedTools/AdvancedToolEditView';

export default ({ advancedToolId }) => (
  <Layout type="provider">
    <AdvancedToolEditView advancedToolId={advancedToolId} />
  </Layout>
);
