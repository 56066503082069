/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';
import { navigate } from 'gatsby';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { Close, Info } from '@material-ui/icons';
import { Container } from '@material-ui/core';

import { parseGraphqlErrors } from '../../utils/FormikUtils';
import Loading from '../Common/Loading';
import { isEmpty } from '../../utils/ObjectUtils';
import { GET_ADVANCED_TOOLS, GET_ADVANCED_TOOL, UPDATE_ADVANCED_TOOL } from '../../queries/AdvancedTools/AdvancedTools';
import { AdvancedToolForm } from './AdvancedToolForm';
import { ArticleContainerUI } from '../Common/styled/ArticleContainerUI';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

export const AdvancedToolEditView = ({ advancedToolId }) => {
  const { t } = useTranslation();
  const provider = useSelector((state) => state.provider);
  const [keycloak] = useKeycloak();
  const [serverError, setServerError] = useState(false);

  const { loading, data } = useQuery(
    GET_ADVANCED_TOOL,
    { variables: { uuid: advancedToolId } },
  );

  if (!provider) {
    return t('you.are.not.allowed.to.perform.this.action');
  }

  const advancedTool = data && data.advancedTool;
  const isAdvancedToolAdmin = advancedTool && keycloak && advancedTool.provider.owner.uuid === keycloak.subject;

  const [updateAdvancedTool, { loading: updating }] = useMutation(
    UPDATE_ADVANCED_TOOL,
    {
      onCompleted: () => {
        navigate(`/advanced-tools/detail/${advancedToolId}`);
      },
      refetchQueries: [{
        query: GET_ADVANCED_TOOLS,
        variables: { providerUuid: provider.uuid },
      }],
      awaitRefetchQueries: true,
    },
  );

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      const input = {
        uuid: advancedToolId,
        ...values,
      };
      await updateAdvancedTool({ variables: { input } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const getApplicationPath = (tool) => {
    if (isEmpty(tool)) return null;
    return {
      advancedTool: {
        name: tool.name,
        link: `/advanced-tools/details/${tool.uuid}`,
      },
    };
  };

  const goBack = () => navigate('/dashboard');

  const handleView = (evt) => {
    evt.stopPropagation();
    navigate(`/advanced-tools/detail/${advancedTool.uuid}`);
  };

  const buttons = [
    { name: 'view.tool.info', icon: Info, handleClick: handleView, disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'go.back', icon: Close, handleClick: goBack },
  ];

  return (
    <ArticleContainerUI>
      <Navbar>
        <SectionBar
          title="edit.tool"
          items={buttons}
          breadcrumbs={getApplicationPath(advancedTool)}
        />
      </Navbar>
      {isAdvancedToolAdmin ? (
        <Container maxWidth="lg" className="article">
          <ScrollableContainer>
            {loading || isEmpty(data)
              ? <Loading />
              : (
                <AdvancedToolForm
                  advancedTool={data.advancedTool}
                  submitLabel={t('edit')}
                  handleSubmit={handleSubmit}
                  serverError={serverError}
                  onClose={goBack}
                  submitting={updating}
                />
              )}
          </ScrollableContainer>
        </Container>
      ) : (
        <AlertWrapperUI>
          <AlertUI severity="warning" title={t('access.forbidden')}>
            {t('you.are.not.allowed.to.perform.this.action')}
          </AlertUI>
        </AlertWrapperUI>
      )}
    </ArticleContainerUI>
  );
};
